import React, { Fragment, useCallback, memo } from 'react';
import classnames from 'classnames';
import useTracking from 'components/Globals/Analytics';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton, { TertiaryButton } from 'components/uiLibrary/LinkButton';
import Loader from 'components/uiLibrary/Loader';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import { useTranslation } from 'src/i18n';
import usePageContext from 'utils/hooks/usePageContext';
import FilterFacets, { FILTER_LAYOUT } from 'components/Filters/Display/FilterFacets';

import { TP } from 'constants/index';
import { ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';

import { TRACK_EVENTS, VIEW_ALL_SELECTED_PROFESSION_ARTISTS } from 'utils/tracking';
import { COMPONENTS, GOOGLE_OLD_TRACKING_SERVICES, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import classes from './SectionBlock.module.scss';

const getFilterTrackingData = seeAllTrackingData => {
  if (!seeAllTrackingData) {
    return { trackingData: seeAllTrackingData, buttonTrackingData: seeAllTrackingData };
  }
  if (seeAllTrackingData.component === COMPONENTS.SEE_ALL_CTA) {
    return {
      trackingData: {
        ...seeAllTrackingData,
        component: COMPONENTS.FILTERS,
      },
      buttonTrackingData: {
        ...seeAllTrackingData,
        component: COMPONENTS.FILTER_CTA,
      },
    };
  }
  if (seeAllTrackingData.subComponent === SUB_COMPONENTS.SEE_ALL_CTA) {
    return {
      trackingData: {
        ...seeAllTrackingData,
        subComponent: SUB_COMPONENTS.FILTERS,
      },
      buttonTrackingData: {
        ...seeAllTrackingData,
        subComponent: SUB_COMPONENTS.FILTER_CTA,
      },
    };
  }

  if (seeAllTrackingData.subComponent) {
    return {
      trackingData: {
        ...seeAllTrackingData,
        subComponent: `${seeAllTrackingData.subComponent} / ${SUB_COMPONENTS.FILTERS}`,
      },
      buttonTrackingData: {
        ...seeAllTrackingData,
        subComponent: `${seeAllTrackingData.subComponent} / ${SUB_COMPONENTS.FILTER_CTA}`,
      },
    };
  }

  return { trackingData: seeAllTrackingData, buttonTrackingData: seeAllTrackingData };
};

const HeadingText = ({ title, styles }) => (
  <Typography
    variant="h2"
    className={classnames(classes.titleText, { [styles?.title]: !!styles?.title })}
    size={16}
    weight="bold"
    color="inherit"
    inline
  >
    {title}
  </Typography>
);

const Count = ({ count }) => (
  <Typography color="secondary" className={classes.count} size="12" weight="medium" inline>
    {` ${count}`}
  </Typography>
);

export const SectionHeading = memo(({ title, count, styles, linkProps, trackingData, disabledLink = false }) => {
  if (!linkProps) {
    return (
      <div className={classes.title}>
        <HeadingText title={title} styles={styles} />
        {count > 0 && <Count count={count} />}
      </div>
    );
  }

  return (
    <Typography
      variant="h2"
      className={classnames(classes.linkTitle, { [styles?.title]: !!styles?.title })}
      size={16}
      weight="bold"
      color="inherit"
      inline
    >
      <LinkButton
        variant="text"
        isLink
        skipTracking={false}
        disabled={disabledLink}
        scroll
        {...linkProps}
        styles={{
          root: classnames(classes.title, { [classes.title__isLink]: !!linkProps }),
        }}
        disableHover
        disableUnderline
        trackingData={trackingData}
      >
        {title}
      </LinkButton>
      {count > 0 && <Count count={count} />}
      <SpriteIcon
        className={classnames(classes.linkIcon, { [classes.linkIconWithoutCount]: !!count })}
        icon="chevron_right"
        size={16}
      />
    </Typography>
  );
});

function extractInnerText(element) {
  if (typeof element === 'string') {
    // NOTE: If it's a string, it's the inner text, return it
    return element;
  }

  // NOTE: If it's not a string, it's a React element object
  if (Array.isArray(element?.props?.children)) {
    // NOTE: If children is an array, iterate through each child element
    return element.props.children.map(child => extractInnerText(child)).join(' ');
  }
  return '';
}

const SeeAll = ({ linkProps, showIcon, suffixTitle, title, entityName, trackingData, isMobile }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const { baseRoute, entity = {} } = usePageContext();

  const handleOnClick = useCallback(() => {
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        id: VIEW_ALL_SELECTED_PROFESSION_ARTISTS,
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  }, [track]);

  const icon = <SpriteIcon icon="navigate_next" />;

  return (
    <LinkButton
      variant="text"
      isLink={!linkProps?.onClick}
      scroll
      {...((showIcon || isMobile) && { rightIcon: icon })}
      {...linkProps}
      title={
        linkProps?.linkTitle ||
        t('VIEW_ALL_TITLE', { ns: 'NS_ENTITY_STUB_PAGE', title: extractInnerText(title), entityName })
      }
      styles={{
        root: classes.seeAllLink,
        icon: classes.seeAllArrow,
      }}
      {...((baseRoute === ROUTE_RESERVED_KEYWORDS.artists || !entity?.id) && { onClick: handleOnClick })}
      trackingData={trackingData}
    >
      {suffixTitle && title
        ? linkProps?.title || t('VIEW_ALL_TITLE', { ns: 'NS_ENTITY_STUB_PAGE', title, entityName })
        : t(`${TP}.VIEW_ALL`)}
    </LinkButton>
  );
};

export const Add = ({ addLinkProps, trackingData = {} }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();

  if (!addLinkProps || !isDesktop) {
    return null;
  }

  return (
    <TertiaryButton
      size={isDesktop ? 'medium' : 'small'}
      variant="text"
      styles={{ root: classes.addBtn }}
      leftIcon={<SpriteIcon icon="add_circle" size={14} className={classes.addBtn__icon} />}
      {...addLinkProps}
      isLink
      scroll
      trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.ADD }}
    >
      {t('CL_CTA_ADD')}
    </TertiaryButton>
  );
};

export const Edit = ({ linkProps, isCustomization, trackingData = {} }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();

  if (!linkProps) {
    return null;
  }

  return (
    <TertiaryButton
      variant="text"
      size={!isDesktop ? 'small' : 'medium'}
      leftIcon={<SpriteIcon icon="edit" size={14} className={classes.editBtn__icon} />}
      styles={{
        root: classes.editBtn,
      }}
      isLink
      {...linkProps}
      scroll
      trackingData={{ ...trackingData, subComponent: isCustomization ? COMPONENTS.CUSTOMIZE_CTA : COMPONENTS.EDIT_CTA }}
    >
      {t(`${TP}.${isCustomization ? 'FN_CUSTOMISE' : 'm_EDIT'}`)}
    </TertiaryButton>
  );
};

const SectionBlock = ({
  title,
  description,
  filterProps,
  linkProps,
  enableSeeAll = true,
  enableViewAllButton = false,
  children,
  count,
  className,
  styles,
  dataId,
  withTitle = true,
  titleChildren,
  isLoading = false,
  seeAllTrackingData,
  hideSectionDivider,
  disabledLink = false,
  wrapContent = false,
}) => {
  const { isMobile } = useDeviceTypeLayouts();
  const { permissions, entity } = usePageContext();
  const showViewAllButton = enableSeeAll && linkProps;
  const hasActions = filterProps?.types?.length > 0 || showViewAllButton;

  if (withTitle && !title) {
    return null;
  }

  const ContentWrapper = wrapContent ? 'div' : Fragment;

  const dataAttributes = dataId ? { 'data-sectionid': dataId } : {};

  return (
    <section
      className={classnames(classes.sectionBlock, {
        [className]: !!className,
        [styles?.root]: !!styles?.root,
      })}
      {...dataAttributes}
    >
      {withTitle && (
        <div
          className={classnames(classes.header, {
            [classes.header__hideSectionDivider]: !permissions?.isTargetMyProfile || hideSectionDivider,
            [classes.header__hasDescription]: !!description,
            [styles?.header]: !!styles?.header,
          })}
        >
          <div
            className={classnames(classes.leftBoxIcon, {
              [styles?.leftBoxIcon]: !!styles?.leftBoxIcon,
            })}
          >
            <SectionHeading
              title={title}
              linkProps={linkProps}
              count={count}
              styles={styles}
              disabledLink={disabledLink}
              trackingData={seeAllTrackingData}
            />
            {titleChildren}
          </div>
          {hasActions && (
            <div className={classes.header__actions}>
              {filterProps?.types?.length > 0 && (
                <div className={classes.header__actions_item}>
                  <FilterFacets
                    {...filterProps}
                    layout={FILTER_LAYOUT.BUTTON}
                    {...getFilterTrackingData(seeAllTrackingData)}
                  />
                </div>
              )}
              {showViewAllButton && (
                <div className={classes.header__actions_item}>
                  <SeeAll linkProps={linkProps} title={title} trackingData={seeAllTrackingData} isMobile={isMobile} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {description && (
        <Typography size="12" color="secondary" className={classes.description}>
          {description}
        </Typography>
      )}
      <ContentWrapper {...(wrapContent ? { className: classnames(classes.content, styles?.content) } : {})}>
        {isLoading ? <Loader /> : children || null}
      </ContentWrapper>
      {enableViewAllButton && (
        <div className={classes.seeAllButton}>
          <SeeAll
            entityName={entity?.name}
            linkProps={linkProps}
            title={title}
            suffixTitle
            showIcon
            trackingData={seeAllTrackingData}
          />
        </div>
      )}
    </section>
  );
};

export default SectionBlock;
